import React from "react"
import Logo from "../../components/Logo/Logo"
import { Helmet } from "react-helmet"
import { ReactComponent as IconDispatch } from "../../icons/cloud-monitor-download.svg"
import { ReactComponent as IconMobileApp } from "../../icons/phone-action-wifi-1.svg"
import { ReactComponent as IconWorkflow } from "../../icons/accounting-calculator-2.svg"
import { ReactComponent as IconTruckVolume } from "../../icons/pie-line-graph-desktop.svg"
import { ReactComponent as IconGPS } from "../../icons/antenna-1.svg"
import { Link } from "gatsby"

const h1Style = "text-3xl font-bold mb-6 text-yellow-500 leading-300"

export default function PagePartnersAAA() {
  return (
    <>
      <Helmet>
        <title>{`Towbook Towing Software - Cloud based towing software for dispatching, impounds, & accounting`}</title>
        <meta
          name="description"
          content="Towbook provides cloud based towing software for dispatching, impounds and reporting. iPhone and Android apps available. Free 30-day towing software trial available."
        />
        <meta
          name="keywords"
          content="towing software, towing management software, web based towing software, impound management software, storage lot software, private property impound software"
        />
        <meta property="fb:page_id" content="73837129791" />
        <meta property="fb:admins" content="527370189" />
        <meta property="og:title" content="Towbook Management Software" />
        <meta property="og:type" content="product" />
        <meta property="og:site_name" content="Towbook" />
        <meta property="og:url" content="https://towbook.com/" />
        <meta property="og:phone_number" content="810-320-5063" />
        <meta
          property="og:description"
          content="Towbook Towing Software is an easy to use web-based management software product for towing companies of all sizes. Dispatching, Impounds, Accounting and more, all accessible from any device with internet access."
        />
        <html lang="en" />
        <body className="font-sans text-gray-100 bg-gray-800 font-400" />
      </Helmet>
      <div>
        <div className="mx-auto p-12" style={{ maxWidth: "700px" }}>
          <div className="flex justify-center mb-12">
            <Link to="/">
              <Logo className="h-16" />
            </Link>
          </div>
          <div
            className="px-8 mb-12 relative overflow-hidden"
            style={{ aspectRatio: "1.35/1" }}
          >
            <div
              className="bg-gradient-to-b from-transparent to-gray-800 absolute left-0 right-0 bottom-0 z-10"
              style={{ top: "40%" }}
            />
            <img src="/screenshots/enroute-vegas.png" />
          </div>
          <h1 className={h1Style}>The Most Trusted Towing Software, Period.</h1>
          <p className="mb-12 text-lg">
            Independently owned and driven by industry needs, Towbook is
            committed to being a brand that supports towing & roadside companies
            of all sizes by providing innovative dispatching solutions.
          </p>
          <h1 className={h1Style}>Special Offer for AAA Service Providers</h1>
          <ul className="mb-12 list-disc list-outside text-lg pl-4 font-500 flex flex-col gap-2">
            <li>
              6-Month Free Trial for AAA Service Providers New to Towbook
              <div className="font-400 italic text-gray-200">
                (Ultimate Plus Plan, $1,734 Value)
              </div>
            </li>
            <li>Dedicated Account Manager</li>
            <li>Complimentary Onboarding & Setup</li>
            <li>24/7 Lifetime Support & Training</li>
            <li>Ability to Import Data</li>
          </ul>
          <div className="flex justify-center mb-12">
            <a
              href="/signup/aaa"
              target="_parent"
              className="flex-shrink-0 block px-8 py-3 text-center text-gray-900 whitespace-no-wrap bg-yellow-500 rounded-md hover:bg-yellow-400 font-400 text-lg"
            >
              Get Started Now!
            </a>
          </div>
          <h1 className={h1Style}>Features of Towbook</h1>
          <div className="mb-12 text-lg flex flex-col gap-10">
            <div className="flex gap-6 items-center">
              <div className="text-blue-400 w-12 h-12">
                <IconDispatch />
              </div>
              <div>Intuitive Cloud-Based Dispatching Platform</div>
            </div>
            <div className="flex gap-6 items-center">
              <div className="text-blue-400 w-12 h-12">
                <IconMobileApp />
              </div>
              <div>Powerful Mobile Apps</div>
            </div>
            <div className="flex gap-6 items-center">
              <div className="text-blue-400 w-12 h-12">
                <IconWorkflow />
              </div>
              <div>Advanced Billing Tools</div>
            </div>
            <div className="flex gap-6 items-center">
              <div className="text-blue-400 w-12 h-12">
                <IconTruckVolume />
              </div>
              <div>Comprehensive Reporting</div>
            </div>
            <div className="flex gap-6 items-center">
              <div className="text-blue-400 w-12 h-12">
                <IconGPS />
              </div>
              <div>Integrated GPS Tracking</div>
            </div>
          </div>
          <div className="px-8 mb-12">
            <div className="aspect-16/9">
              <iframe
                src="https://player.vimeo.com/video/798757513?h=3d1ed316f4&color=0177cb&title=0&byline=0&portrait=0"
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Towbook Overview Video"
              ></iframe>
            </div>
          </div>
          <div className="rich-text">
            <p>For more information, please contact:</p>
            <p>
              Allison Smith
              <br />
              <a href="tel:+18103205063">(810) 320-5063</a>
              <br />
              <a href="mailto:support@towbook.com">support@towbook.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
